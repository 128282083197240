export const classNames = (names: { [key: string]: boolean }) => {
  return Object.keys(names).reduce((clazz, key) => {
    if (names[key]) {
      if (clazz) {
        clazz += " "
      }
      clazz += `${key}`
    }
    return clazz
  }, "")
}

export const getPathName = () => {
  let pathname = "";
  if (typeof window !== "undefined") {
    pathname = window.location.pathname
  }
  return pathname
}
