import React from "react";
import Section from "./section";
import styled from "styled-components";
import { SP_WIDTH, THEME_MAIN_COLOR } from "../config/Config";
import Copyright from "./Copyright";
import LinkWrapper from "./LinkWrapper";

const Div = styled.div`
  text-align: center;
  padding: 32px 0;

  ul {
    list-style: none;
    margin: 0;
    display: inline-block;
    li {
      display: inline;

      @media screen and (max-width: ${SP_WIDTH}px) {
        display: block;
      }
      margin-right: 8px;
    }
  }
`;

const Credit = styled.small`
  color: #fff;
  font-size: 11px;
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = () => (
  <footer>
    <Section backgroundColor={THEME_MAIN_COLOR} noMarginContent={true}>
      <Div>
        <ul>
          <li>
            <LinkWrapper to="/">Top</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/guide">診療案内</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/introduction">院内紹介</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/facility">設備紹介</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/greet">院長挨拶</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/access">アクセス</LinkWrapper>
          </li>
        </ul>
        <div>
          <Copyright name="大渕耳鼻咽喉科クリニック" startYear={2019} />
          {/* <br />
          <Credit>
            Designed by{" "}
            <a href="https://blog.kwst.site" target="_blank">
              kwst.site
            </a>
          </Credit> */}
        </div>
      </Div>
    </Section>
  </footer>
);

export default Footer;
