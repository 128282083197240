import React from "react";
import styled from "styled-components";
import { SP_WIDTH } from "../config/Config";

const Section = ({
  children,
  backgroundColor,
  noMarginContent
}: {
  children: any[] | any;
  backgroundColor?: string;
  noMarginContent?: boolean;
}) => {
  const StyledSection = styled.section`
    .content {
      margin: ${noMarginContent ? "0 auto" : "24px auto 48px"};
      max-width: 1024px;
      padding: 0 16px;
      @media screen and (max-width: ${SP_WIDTH}px) {
        padding: 0 8px;
      }
    }
  `;

  return (
    <StyledSection style={{ backgroundColor: backgroundColor || "inherit" }}>
      <div className="content">{children}</div>
    </StyledSection>
  );
};

export default Section;
