import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import * as Sentry from "@sentry/browser";
Sentry.init({
  dsn: "https://650b4125eba248c2b0789299e0a34302@sentry.io/1520594"
});

import Header from "./header";
import "./layout.css";
import Footer from "./footer";

const Layout = ({
  children,
  isFloatHeader
}: {
  children: any[] | any;
  isFloatHeader?: boolean;
}) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: "description",
                  content: data.site.siteMetadata.description
                }
              ]}
            >
              <link
                href="https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c"
                rel="stylesheet"
              />
              <html lang="ja" />
            </Helmet>
            <Header
              siteTitle={data.site.siteMetadata.title}
              isFloat={isFloatHeader}
            />
            <div
              style={{
                paddingTop: 0
              }}
            >
              {children}
            </div>
            <Footer />
          </>
        );
      }}
    />
  );
};

export default Layout;
