import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { THEME_MAIN_COLOR } from "../config/Config";
import Logo from "./logo";
import Navigation from "./navigation";
import Hamburger from "./hamburger";

const HeaderComponent = styled.header`
  .hamburger {
    display: none;
  }
  .navigation {
    display: block;
  }
  .container {
    margin: "0 auto",
    max-width: 960px;
    padding: 0.8rem 1.0875rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 750px) {
      padding: 5px 8px;
    }
  }

  @media screen and (max-width: 960px) {
    .hamburger {
      display: block;
    }
    .navigation {
      display: none;
    }
  }
`;

const Title = styled.h1`
  margin: 0 0 0 8px;
  flex-grow: 1;
  font-size: 40px;

  @media screen and (max-width: 900px) {
    font-size: 32px;
  }

  @media screen and (max-width: 750px) {
    font-size: 21px;
  }

  @media screen and (max-width: 400px) {
    font-size: 19px;
  }
`;

const Header = ({
  siteTitle,
  isFloat,
}: {
  siteTitle: string;
  isFloat?: boolean;
}) => (
  <HeaderComponent
    style={{
      background: THEME_MAIN_COLOR,
      position: isFloat ? "absolute" : "relative",
      top: isFloat ? "32px" : 0,
      width: "100%",
      zIndex: 2,
    }}
  >
    <div className="container">
      <Logo />
      <Title>
        <Link
          to="/"
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          {siteTitle}
        </Link>
      </Title>
      <div className="navigation">
        <Navigation />
      </div>
      <div className="hamburger">
        <Hamburger />
      </div>
    </div>
  </HeaderComponent>
);

export default Header;
