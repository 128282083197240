import React from 'react'
import { Link } from 'gatsby'
import { getPathName } from '../util/Util'

const LinkWrapper = ({ to, children }: { to: string, children: string }) => {
  return <Link to={to} style={{
    textDecoration: getPathName() === to || getPathName() === to + "/" ? "underline" : "none",
    color: "#fff"
  }}>{children}</Link>
}

export default LinkWrapper