import React, { useState } from "react";
import styled from "styled-components";
import { classNames } from "../util/Util";
import LinkWrapper from "./LinkWrapper";
import { THEME_MAIN_COLOR } from "../config/Config";
const hanburgerIcon = require("../assets/hamburger.svg");
const closeIcon = require("../assets/close.svg");

const Wrapper = styled.div`
  nav {
    padding: 16px;
  }

  .menu {
    position: fixed;
    background: ${THEME_MAIN_COLOR};
    height: 100vh;
    top: 0;
    width: 40vw;
    left: 100vw;
    transition: left 0.4s;
    z-index: 11;

    ul {
      margin: 16px 0 0 0;
      list-style: none;
      li > a {
        display: block;
      }
    }
  }
  .menu.open {
    left: 60vw;
  }

  .cover {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s;
    z-index: 10;
  }
`;

const Hamburger = () => {
  const [isOpen, toggle] = useState(false);

  return (
    <Wrapper>
      <div>
        <button
          style={{ background: "none", border: "none" }}
          onClick={() => {
            toggle(!isOpen);
          }}
        >
          <img
            style={{ filter: "invert(1)" }}
            src={hanburgerIcon}
            alt="メニュー"
          />
        </button>
      </div>
      {isOpen ? <div className="cover" onClick={() => toggle(false)} /> : null}
      <nav className={classNames({ menu: true, open: isOpen })}>
        <button
          style={{ background: "none", border: "none" }}
          onClick={() => toggle(false)}
        >
          <img
            style={{ filter: "invert(1)", width: 20, height: 20 }}
            src={closeIcon}
            alt="閉じる"
          />
        </button>
        <ul>
          <li>
            <LinkWrapper to="/">Top</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/guide">診療案内</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/introduction">院内紹介</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/facility">設備紹介</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/greet">院長挨拶</LinkWrapper>
          </li>
          <li>
            <LinkWrapper to="/access">アクセス</LinkWrapper>
          </li>
        </ul>
      </nav>
    </Wrapper>
  );
};

export default Hamburger;
