import React from "react";
import styled from "styled-components";
import LinkWrapper from "./LinkWrapper";

const Nav = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    display: flex;
    li {
      margin: 0 8px 0 0;
      font-size: 16px;
    }
  }
`;

const Navigation = () => (
  <Nav>
    <ul>
      <li>
        <LinkWrapper to="/">Top</LinkWrapper>
      </li>
      <li>
        <LinkWrapper to="/guide">診療案内</LinkWrapper>
      </li>
      <li>
        <LinkWrapper to="/introduction">院内紹介</LinkWrapper>
      </li>
      <li>
        <LinkWrapper to="/facility">設備紹介</LinkWrapper>
      </li>
      <li>
        <LinkWrapper to="/greet">院長挨拶</LinkWrapper>
      </li>
      <li>
        <LinkWrapper to="/access">アクセス</LinkWrapper>
      </li>
    </ul>
  </Nav>
);

export default Navigation;
