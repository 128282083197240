import React from "react";
import styled from "styled-components";
const logo = require("../images/logo-nega.png");

const Img = styled.img`
  width: 40px;
  @media screen and (max-width: 750px) {
    width: 32px;
  }

  @media screen and (max-width: 400px) {
    width: 24px;
  }
`;

const Logo = () => <Img src={logo} alt="フクロウのロゴ" />;

export default Logo;
